import React from "react";
import copy from "../assets/images/copy.svg";
import dismiss from "../assets/images/dismiss.svg";
import {toast} from "react-hot-toast";
import {message} from "antd";

const ErrorMessage = ({errorMessage, code}) => {

    const errMessage = `${errorMessage} ${code}`;

    const handleCopyCode = () => {
        navigator.clipboard.writeText(errMessage).then(() => {
           message.success("code copied");
        });
    }

    return <div className={"error-msg-wrapper"}>
        <div className={"left"}>
            <img onClick={() => toast.dismiss()} src={dismiss} alt=""/>
            <div className={"messages"}>
                <span style={{textTransform: "capitalize"}}>{errorMessage}</span>
                <span>Error ID: <b>{code}</b></span>
            </div>
        </div>
        <img className={"copy-img"} onClick={handleCopyCode} src={copy} alt=""/>
    </div>
}

export default ErrorMessage;
