import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./libs";
import Noti from "./assets/here-i-am-449.mp3";
import { notification } from "antd";
import {useDarkModeContext} from "./contexts/DarkMode";

const checkIfBeamIsSupported = () =>
    "serviceWorker" in navigator ||
    "PushManager" in window ||
    "Notification" in window;

const App = () => {

  const {setIsDarkMode} = useDarkModeContext();

  const [notificationMessage, setNotification] = useState({
    title: "",
    body: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const notiRef = useRef();

  useEffect(() => {
    (async () => {
      if (checkIfBeamIsSupported()) {
        const PusherPushNotifications = require("@pusher/push-notifications-web");
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: "76ece59f-2e35-4a46-9312-dca21a79df99",
        });
        beamsClient
            .start()
            .then((beamsClient) => beamsClient.getDeviceId())
            .then((deviceId) => {
              return setTokenFound({ found: true, token: deviceId });
            })
            .then(() => beamsClient.addDeviceInterest("trades"))
            .then(() => beamsClient.getDeviceInterests())
            .then((interests) => console.log("Current interests:", interests))
            .catch(console.error, "err");
      }
    })();
  }, []);

  const openNotification = () => {
    notification.info({
      message: "New Notification",
      description: notificationMessage.body,
    });
  };
  if (checkIfBeamIsSupported()) {
    navigator?.serviceWorker?.addEventListener("message", (event) => {
      setNotification({ ...event.data.notification });
      openNotification();
      notiRef?.current?.play();
    });
  }

  useEffect(() => {
    const currentMode = localStorage.getItem("adminDarkMode") === "true" ?  true : false;
    setIsDarkMode(currentMode)
  }, []);


  return (
      <React.Fragment>
        <audio ref={notiRef} src={Noti} />
        <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
      </React.Fragment>
  );
};

export default App;
