import adminActionTypes from "../types/admin.type";
const INITIAL_STATE = {
  users: [],
  error: null,
  success: null,
  isLoading: false,
  getUserError: null,
  getUserSuccess: null,
  transactions: [],
  trades: [],
  rates: [],
  settings: [],
  categories: [],
  balance: [],
  giftCards: [],
  settingsLoading: false,
  subCategoryError: null,
  subCategorySuccess: null,
  balanceLoading: false,
  subCategoryLoading: false,
  settingsSuccess: null,
  settingsFailure: null,
  categoryLoading: false,
  categoryError: null,
  categorySuccess: null,
  tradeLoading: false,
  tradeError: null,
  tradeSuccess: null,
  tradeBuyLoading: false,
  tradeBuyError: null,
  tradeBuySuccess: null,
  tradesBuy: [],
  transactionLoading: false,
  transactionError: null,
  transactionSuccess: null,
  editUserError: null,
  editUserSuccess: null,
  editUserLoading: false,
  banUserLoading: false,
  banUserError: null,
  banUserSuccess: null,
  deleteUserLoading: false,
  deleteUserError: null,
  deleteUserSuccess: null,
  editRateLoading: false,
  editRateError: null,
  editRateSuccess: null,
  getReasonsLoading: false,
  getReasonsError: null,
  getReasonsSuccess: null,
  pagination: {},
  paginationBuy: {},
  reasons: [],
  giftcodes: [],
  getGiftcodesSuccess: null,
  getGiftcodesLoading: false,
  getGiftcodesError: null,
  topup: [],
  getTopupSuccess: null,
  getTopupLoading: false,
  getTopupError: null,
  refill: [],
  getRefillSuccess: null,
  getRefillLoading: false,
  getRefillError: null,
  giftCardLoading: false,
  giftCardError: null,
  giftCardSuccess: null,
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case adminActionTypes.GET_GIFTCARD_START:
      return {
        ...state,
        giftCardLoading: true,
        giftCardError: null,
        giftCardSuccess: null,
      };
    case adminActionTypes.GET_GIFTCARD_SUCCESS:
      return {
        ...state,
        giftCards: action.payload,
        giftCardLoading: false,
        giftCardError: null,
        giftCardSuccess: action.payload,
      };
    case adminActionTypes.GET_GIFTCARD_FAILURE:
      return {
        ...state,
        giftCardLoading: false,
        giftCardError: action.payload,
        giftCardSuccess: null,
        giftCards: [],
      };
    case adminActionTypes.GET_REFILL_START:
      return {
        ...state,
        getRefillLoading: true,
        getRefillError: null,
        getRefillSuccess: null,
      };
    case adminActionTypes.GET_REFILL_SUCCESS:
      return {
        ...state,
        getRefillLoading: false,
        getRefillError: null,
        getRefillSuccess: action.payload,
        refill: action.payload,
      };
    case adminActionTypes.GET_REFILL_FAILURE:
      return {
        ...state,
        getRefillLoading: false,
        getRefillError: action.payload,
        getRefillSuccess: null,
      };
    case adminActionTypes.GET_BUY_TRADES_START:
      return {
        ...state,
        tradeBuyLoading: true,
        tradeBuyError: null,
        tradeBuySuccess: null,
        paginationBuy: {},
      };
    case adminActionTypes.GET_BUY_TRADES_SUCCESS:
      return {
        ...state,
        tradeBuyLoading: false,
        tradeBuyError: null,
        tradeBuySuccess: action.payload,
        tradesBuy: action.payload,
      };
    case adminActionTypes.GET_BUY_TRADES_FAILURE:
      return {
        ...state,
        tradeBuyLoading: false,
        tradeBuyError: action.payload,
        tradeBuySuccess: null,
      };
    case adminActionTypes.GET_TOPUP_START:
      return {
        ...state,
        getTopupLoading: true,
        getTopupError: null,
        getTopupSuccess: null,
      };
    case adminActionTypes.GET_TOPUP_SUCCESS:
      return {
        ...state,
        getTopupLoading: false,
        getTopupError: null,
        getTopupSuccess: action.payload,
        topup: action.payload,
      };
    case adminActionTypes.GET_TOPUP_FAILURE:
      return {
        ...state,
        getTopupLoading: false,
        getTopupError: action.payload,
        getTopupSuccess: null,
      };
    case adminActionTypes.GET_GIFTCODES_START:
      return {
        ...state,
        getGiftcodesLoading: true,
        getGiftcodesError: null,
        getGiftcodesSuccess: null,
      };
    case adminActionTypes.GET_GIFTCODES_SUCCESS:
      return {
        ...state,
        getGiftcodesLoading: false,
        getGiftcodesError: null,
        getGiftcodesSuccess: action.payload,
        giftcodes: action.payload,
      };
    case adminActionTypes.GET_GIFTCODES_FAILURE:
      return {
        ...state,
        getGiftcodesLoading: false,
        getGiftcodesError: action.payload,
        getGiftcodesSuccess: null,
      };
    case adminActionTypes.GET_REASONS_START:
      return {
        ...state,
        getReasonsLoading: true,
        getReasonsError: null,
        getReasonsSuccess: null,
      };
    case adminActionTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        getReasonsLoading: false,
        getReasonsError: null,
        getReasonsSuccess: action.payload,
        reasons: action.payload,
      };
    case adminActionTypes.GET_REASONS_FAILURE:
      return {
        ...state,
        getReasonsLoading: false,
        getReasonsError: action.payload,
        getReasonsSuccess: null,
      };
    case adminActionTypes.GET_USERS_START:
      return {
        ...state,
        error: null,
        users: [],
        success: null,
        isLoading: true,
        pagination: {},
        editUserSuccess: null,
        getUserError: null,
      };
    case adminActionTypes.SETTINGS_START:
      return {
        ...state,
        settingsSuccess: null,
        settingsFailure: null,
        settingsLoading: true,
      };
    case adminActionTypes.SETTINGS_FAILURE:
      return {
        ...state,
        settingsSuccess: null,
        settingsFailure: action.payload,
        settingsLoading: true,
      };
    case adminActionTypes.SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        settingsSuccess: action.payload,
        settingsFailure: null,
        settingsLoading: false,
      };
    case adminActionTypes.EDIT_RATES_START:
      return {
        ...state,
        editRateLoading: true,
        editRateError: null,
        editRateSuccess: null,
      };
    case adminActionTypes.EDIT_RATES_SUCCESS:
      return {
        ...state,
        editRateLoading: false,
        editRateError: null,
        editRateSuccess: action.payload,
      };
    case adminActionTypes.EDIT_RATES_FAILURE:
      return {
        ...state,
        editRateLoading: false,
        editRateError: action.payload,
        editRateSuccess: null,
      };
    case adminActionTypes.EDIT_USERS_START:
      return {
        ...state,
        editUserLoading: true,
        editUserSuccess: null,
        editUserError: null,
      };
    case adminActionTypes.EDIT_USERS_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: action.payload,
        editUserError: null,
      };
    case adminActionTypes.EDIT_USERS_FAILURE:
      return {
        ...state,
        editUserLoading: false,
        editUserError: action.payload,
        editUserSuccess: null,
      };
    case adminActionTypes.BAN_USERS_START:
      return {
        ...state,
        banUserError: null,
        banUserSuccess: null,
        banUserLoading: true,
      };
    case adminActionTypes.BAN_USERS_SUCCESS:
      return {
        ...state,
        banUserError: null,
        banUserSuccess: action.payload,
        banUserLoading: false,
      };
    case adminActionTypes.BAN_USERS_FAILURE:
      return {
        ...state,
        banUserError: action.payload,
        banUserSuccess: null,
        banUserLoading: false,
      };
    case adminActionTypes.DELETE_USERS_START:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserError: null,
        deleteUserSuccess: null,
      };
    case adminActionTypes.DELETE_USERS_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: null,
        deleteUserSuccess: action.payload,
      };
    case adminActionTypes.DELETE_USERS_FAILURE:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: action.payload,
        deleteUserSuccess: null,
      };
    case adminActionTypes.GET_USER_BALANCE_START:
      return {
        ...state,
        balanceLoading: true,
        balance: [],
      };

    case adminActionTypes.GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        balance: action.payload,
      };
    case adminActionTypes.GET_TRANSACTIONS_START:
      return {
        ...state,
        transactionLoading: true,
        transactionError: null,
        transactionSuccess: null,
        pagination: {},
      };
    case adminActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionLoading: false,
        transactionError: null,
        transactionSuccess: action.payload,
        transactions: action.payload,
      };
    case adminActionTypes.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactionLoading: false,
        transactionError: action.payload,
        transactionSuccess: null,
      };
    case adminActionTypes.GET_TRADES_START:
      return {
        ...state,
        tradeLoading: true,
        tradeError: null,
        tradeSuccess: null,
        pagination: {},
      };
    case adminActionTypes.GET_TRADES_SUCCESS:
      return {
        ...state,
        tradeLoading: false,
        tradeError: null,
        tradeSuccess: action.payload,
        trades: action.payload,
      };
    case adminActionTypes.GET_TRADES_FAILURE:
      return {
        ...state,
        tradeLoading: false,
        tradeError: action.payload,
        tradeSuccess: null,
      };
    case adminActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        success: action.payload,
        error: null,
        isLoading: false,
      };
    case adminActionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case adminActionTypes.SET_PAGINATION_BUY:
      return {
        ...state,
        paginationBuy: action.payload,
      };

    case adminActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        success: null,
      };
    case adminActionTypes.GET_SUBCATEGORY_START:
      return {
        ...state,
        subCategoryLoading: true,
        subCategoryError: null,
        subCategorySuccess: null,
      };
    case adminActionTypes.GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        rates: action.payload,
        subCategoryLoading: false,
        subCategoryError: null,
        subCategorySuccess: action.payload,
      };
    case adminActionTypes.GET_SUBCATEGORY_FAILURE:
      return {
        ...state,
        subCategoryLoading: false,
        subCategoryError: action.payload,
        subCategorySuccess: null,
        rates: [],
      };
    case adminActionTypes.GET_CATEGORY_START:
      return {
        ...state,
        categoryLoading: true,
        categoryError: null,
        categorySuccess: null,
      };
    case adminActionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoryLoading: false,
        categoryError: null,
        categorySuccess: action.payload,
      };
    case adminActionTypes.GET_CATEGORY_FAILURE:
      return {
        ...state,
        categoryLoading: false,
        categoryError: action.payload,
        categorySuccess: null,
        rates: [],
      };
    default:
      return state;
  }
};
export default adminReducer;
