import authActionTypes from "../types/auth.type";
const INITIAL_STATE = {
  currentUser: null,
  accessToken: null,
  refreshToken: null,
  error: null,
  success: null,
  isLoading: false,
  signUpError: null,
  signUpSuccess: null,
  signInError: null,
  signInSuccess: null,
  resetPasswordError: null,
  resetPasswordSuccess: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authActionTypes.SIGN_UP_START:
    case authActionTypes.SIGN_OUT_START:
    case authActionTypes.SIGN_IN_START:
    case authActionTypes.FORGOT_PASSWORD_START:
    case authActionTypes.GET_USER_START:
    case authActionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        success: null,
        error: null,
        signUpError: null,
        signInError: null,
        signUpSuccess: null,
        signInSuccess: null,
        resetPasswordError: null,
        resetPasswordSuccess: null,
      };
    case authActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        signInSuccess: action.payload,
        signInError: null,
        isLoading: false,
      };
    case authActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        signInSuccess: action.payload,
        signUpError: null,
        isLoading: false,
      };

    case authActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
        resetPasswordError: null,
        isLoading: false,
      };
    case authActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        refreshToken: null,
        accessToken: null,
      };
    case authActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        signInError: action.payload,
        signInSuccess: null,
      };
    // it doesn't matter if the logout function fails it should just log the user out
    case authActionTypes.SIGN_OUT_FAILURE:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        refreshToken: null,
        accessToken: null,
      };
    case authActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        signUpError: action.payload,
        signUpSuccess: null,
      };

    case authActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        resetPasswordError: action.payload,
        resetPasswordSuccess: null,
      };

    case authActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
      };
    case authActionTypes.SET_CURRENT_ACCESS_TOKEN:
      return {
        ...state,
        isLoading: false,
        accessToken: action.payload,
      };
    case authActionTypes.SET_CURRENT_REFRESH_TOKEN:
      return {
        ...state,
        isLoading: false,
        refreshToken: action.payload,
      };
    case authActionTypes.LOGOUT:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        refreshToken: null,
        accessToken: null,
      };

    default:
      return state;
  }
};
export default authReducer;
