import { useEffect } from "react";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import {toast} from "react-hot-toast";
import ErrorMessage from "../components/ErrorMessage";

export const useFeedback = (success, error, successMessage) => {
  useEffect(() => {
    if (success) {
      message.success(success) || message.success(successMessage);
    } else if (error) {
      if (error?.error?.message || error?.message) {
        message.error(error?.error?.message || error?.message);
      } else {
        message.error("Something went wrong, please try again later");
      }
    }
  }, [success, error, successMessage]);
};

//validation messages
export const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${placeholder} is required!",
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: "${placeholder} is not a valid email!",
  },
};

export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.file.originFileObj;
};
export const deepIndexOf = (arr, obj) => {
  return arr.findIndex(function (cur) {
    return Object.keys(obj).every(function (key) {
      return obj[key] === cur[key];
    });
  });
};

export function dummyRequest({ file, onSuccess }) {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
}

export const passwordValidation = (_rule, value, callback) => {
  if (
    value &&
    value.match(/[a-z]/g) &&
    value.match(/[A-Z]/g) &&
    value.match(/[0-9]/g) &&
    value.length >= 8
  ) {
    callback();
  } else {
    callback(
      "Password must be longer than eight charaters, contain at least one uppercase letter, at least one lowercase letter, and at least one number."
    );
  }
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const useSearchQuery = (query) => useQuery().get(query);

export const getChanges = (original, changed, options) => {
  return Object.entries(changed).reduce((accumulator, current) => {
    if (
      !original ||
      !original[current[0]] ||
      current[1] !== original[current[0]]
    ) {
      if (!original || !(!original[current[0]] && !current[1])) {
        if (current[1] && typeof current[1] === "object") {
          accumulator[current[0]] =
            options &&
            options.shallowKeys &&
            options.shallowKeys.length > 0 &&
            options.shallowKeys.findIndex((key) => key === current[0]) !== -1
              ? current[1]
              : getChanges(original[current[0]], current[1], options);
        } else {
          if (current[1]) accumulator[current[0]] = current[1];
        }
      }
    }
    return accumulator;
  }, {});
};

export const arrayfyObject = (obj) =>Object.entries(obj).map(([key, value]) => ({ key, ...value }));

// https://stackoverflow.com/a/38616965
// merge two array of object by a common key
// put the array that takes precidence last
export const mergeArraysOfObjectByKey = (arr, clientArray, key) => [
  ...arr
    .concat(clientArray)
    .reduce(
      (m, o) => m.set(o[key], Object.assign(m.get(o[key]) || {}, o)),
      new Map()
    )
    .values(),
];

export const showError = (err) => {
  const message = err?.response?.data?.message;
  const code = err?.response?.data?.meta?.id;

  return toast.custom(<ErrorMessage errorMessage={message} code={code} />, {
    duration: 5000,
    position: "top-right",
    style: {
      border: "4px solid gold",
      width: "400px"
    }
  })
}