const authActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CURRENT_ACCESS_TOKEN: "SET_CURRENT_ACCESS_TOKEN",
  SET_CURRENT_REFRESH_TOKEN: "SET_CURRENT_REFRESH_TOKEN",
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  GET_USER_START: "GET_USER_START",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  LOGOUT: "LOGOUT",
};
export default authActionTypes;
