import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";
import { PersistGate } from "redux-persist/integration/react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
import {DarkModeContextProvider} from "./contexts/DarkMode";
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <DarkModeContextProvider>
            <ConfigProvider>
                <PersistGate persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <Toaster />
                        <App />
                    </QueryClientProvider>
                </PersistGate>
            </ConfigProvider>
        </DarkModeContextProvider>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
// registerServiceWorker();

reportWebVitals();
