import React, { useContext, useEffect, useRef } from "react";

import Navigation from "./Navigation";
import NavBar from "./NavBar";

import useWindowSize from "../../hooks/useWindowSize";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ConfigContext } from "../../contexts/ConfigContext";
import * as actionType from "../../store/actions";
import {useDarkModeContext} from "../../contexts/DarkMode";
import DarkModeToggle from "react-dark-mode-toggle";

const AdminLayout = ({ children }) => {
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const {
    collapseMenu,
    layout,
    subLayout,
    headerFixedLayout,
  } = configContext.state;
  const { dispatch } = configContext;

  const {isDarkMode, setIsDarkMode} = useDarkModeContext();

  const toggleDarkMode = (checked) => {
    localStorage.setItem("adminDarkMode", checked);
    setIsDarkMode(checked);
  };


  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

  useEffect(() => {
    if (
      windowSize.width > 992 &&
      windowSize.width <= 1024 &&
      layout !== "horizontal"
    ) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === "horizontal" && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: "vertical" });
    }
  }, [dispatch, layout, windowSize]);

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ["pcoded-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    mainClass = [...mainClass, "container"];
  }

  let common = (
      <div className={isDarkMode ? "layout dark" : ""}>
        <div className={"toggle-switch"}>

          <DarkModeToggle
              onChange={toggleDarkMode}
              checked={isDarkMode}
              size={70}
          />

        </div>

        <Navigation />
      </div>
  );

  let mainContainer = (
      <div className={isDarkMode ? "layout dark" : ""}>
        <NavBar />
        <div className="pcoded-main-container">
          <div className={mainClass.join(" ")}>
            <div className="pcoded-content">
              <div className="pcoded-inner-content">{children}</div>
            </div>
          </div>
        </div>
      </div>
  );

  if (windowSize.width < 992) {
    let outSideClass = ["nav-outside"];
    if (collapseMenu) {
      outSideClass = [...outSideClass, "mob-backdrop"];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, "mob-fixed"];
    }

    common = (
      <div className={outSideClass.join(" ")} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div className="pcoded-outside" onClick={() => mobileOutClickHandler}>
        {mainContainer}
      </div>
    );
  }

  return (
    <React.Fragment>
      {common}
      {mainContainer}
    </React.Fragment>
  );
};

export default AdminLayout;
