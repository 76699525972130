import {createContext, useContext, useState} from "react";

const DarkModeContext = createContext();

export const DarkModeContextProvider = ({children}) => {
    const [isDarkMode, setIsDarkMode] = useState(true);

    return <DarkModeContext.Provider value={{isDarkMode, setIsDarkMode}}>
        {children}
    </DarkModeContext.Provider>
}

export const useDarkModeContext = () => useContext(DarkModeContext);