const adminActionTypes = {
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  EDIT_USERS_START: "EDIT_USERS_START",
  EDIT_USERS_SUCCESS: "EDIT_USERS_SUCCESS",
  EDIT_USERS_FAILURE: "EDIT_USERS_FAILURE",
  BAN_USERS_START: "BAN_USERS_START",
  BAN_USERS_SUCCESS: "BAN_USERS_SUCCESS",
  BAN_USERS_FAILURE: "BAN_USERS_FAILURE",
  DELETE_USERS_START: "DELETE_USERS_START",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",
  GET_TRANSACTIONS_START: "GET_TRANSACTIONS_START",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",
  GET_TRADES_START: "GET_TRADES_START",
  GET_TRADES_SUCCESS: "GET_TRADES_SUCCESS",
  GET_TRADES_FAILURE: "GET_TRADES_FAILURE",
  GET_SUBCATEGORY_START: "GET_SUBCATEGORY_START",
  GET_SUBCATEGORY_SUCCESS: "GET_SUBCATEGORY_SUCCESS",
  GET_SUBCATEGORY_FAILURE: "GET_SUBCATEGORY_FAILURE",
  GET_CATEGORY_START: "GET_CATEGORY_START",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
  GET_GIFTCARD_START: "GET_GIFTCARD_START",
  GET_GIFTCARD_SUCCESS: "GET_GIFTCARD_SUCCESS",
  GET_GIFTCARD_FAILURE: "GET_GIFTCARD_FAILURE",
  GET_USER_BALANCE_START: "GET_USER_BALANCE_START",
  GET_USER_BALANCE_SUCCESS: "GET_USER_BALANCE_SUCCESS",
  GET_USER_BALANCE_FAILURE: "GET_USER_BALANCE_FAILURE",
  EDIT_RATES_START: "EDIT_RATES_START",
  EDIT_RATES_SUCCESS: "EDIT_RATES_SUCCESS",
  EDIT_RATES_FAILURE: "EDIT_RATES_FAILURE",
  FILTER_TRANSACTIONS_START: "FILTER_TRANSACTIONS_START",
  FILTER_TRANSACTIONS_SUCCESS: "FILTER_TRANSACTIONS_SUCCESS",
  FILTER_TRANSACTIONS_FAILURE: "FILTER_TRANSACTIONS_FAILURE",
  SETTINGS_START: "SETTINGS_START",
  SETTINGS_SUCCESS: "SETTINGS_SUCCESS",
  SETTINGS_FAILURE: "SETTINGS_FAILURE",
  GET_REASONS_START: "GET_REASONS_START",
  GET_REASONS_SUCCESS: "GET_REASONS_SUCCESS",
  GET_REASONS_FAILURE: "GET_REASONS_FAILURE",
  SET_PAGINATION: "SET_PAGINATION",
  GET_GIFTCODES_START: "GET_GIFTCODES_START",
  GET_GIFTCODES_SUCCESS: "GET_GIFTCODES_SUCCESS",
  GET_GIFTCODES_FAILURE: "GET_GIFTCODES_FAILURE",
  GET_TOPUP_START: "GET_TOPUP_START",
  GET_TOPUP_SUCCESS: "GET_TOPUP_SUCCESS",
  GET_TOPUP_FAILURE: "GET_TOPUP_FAILURE",
  GET_REFILL_START: "GET_REFILL_START",
  GET_REFILL_SUCCESS: "GET_REFILL_SUCCESS",
  GET_REFILL_FAILURE: "GET_REFILL_FAILURE",
  GET_BUY_TRADES_START: "GET_BUY_TRADES_START",
  GET_BUY_TRADES_SUCCESS: "GET_BUY_TRADES_SUCCESS",
  GET_BUY_TRADES_FAILURE: "GET_BUY_TRADES_FAILURE",
  SET_PAGINATION_BUY: "SET_PAGINATION_BUY",
};
export default adminActionTypes;
