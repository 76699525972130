import { reducer as formReducer } from "redux-form";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import authReducer from "./reducers/auth.reducer";
import adminReducer from "./reducers/admin.reducer";
const encryptor = encryptTransform({
  secretKey: "stop-looking-for-what-is-not-lost",
});

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["user"],
};
const authPersistConfig = {
  key: "user",
  storage: storage,
  transforms: [encryptor],
  whitelist: ["currentUser", "accessToken", "refreshToken"],
};

const rootReducer = combineReducers({
  user: persistReducer(authPersistConfig, authReducer),
  admin: adminReducer,
  form: formReducer,
});
export default persistReducer(rootPersistConfig, rootReducer);
