import axios from "axios";
import {baseUrl} from "./constants";

const instance = axios.create({baseURL: baseUrl, withCredentials: true});
instance.interceptors.request.use((config) => config);
let isRefreshing = false, now = Date.now(); // stop client from sending multiple refresh request

instance.interceptors.response.use(response => response, async err => {
    const {config, response: {status}} = err;

    if (status === 401) {
        if (!isRefreshing && now < Date.now()) {
            isRefreshing = true;
            now = Date.now() + 10000; // for the next 10 sec don't make refresh request to the server

            try {
                // uses a fresh axios instance passing in the config to avoid infinite loop
                await axios.get(`/auth/refresh`, {baseURL: baseUrl, withCredentials: true});
                isRefreshing = false;
            } catch (e) {
                // if response is 401 refresh token has expired and user session terminated, return to login page
                window.location.replace('/login');
            }
        }

        // if instance is no longer refreshing and still getting 401, mock a request
        if (!isRefreshing && status === 401) await instance.get(`/settings`);

        return Promise.resolve(instance(config));
    }

    return Promise.reject(err);
});

export default instance;