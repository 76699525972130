import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {selectCurrentUser} from "../../store/selectors/auth.selector";

const AuthGuard = ({ children }) => {
  const user = useSelector(selectCurrentUser);

  if (!user?.role) {
    return <Redirect to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
