import { baseUrl } from "./constants";
import instance from "./refreshToken";

const json = {
  "content-type": "application/json",
  Accept: "application/json",
};
const formData = {
  "content-type": "multipart/form-data",
  Accept: "multipart/form-data",
};

export const apiGet = (url, data, header = false, headerData = json) => {
 url=data?`${baseUrl}/${url}?${data}`:`${baseUrl}/${url}`;
  return instance.get(url).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};

export const apiPost = (url, data, header = false, headerData = json) => {
  return instance.post(`${baseUrl}/${url}`, data).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};
export const apiPatch = (url, data, header = false, headerData = json) => {
  return instance.patch(`${baseUrl}/${url}`, data).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};
export const apiPutFD = (url, data, header = false, headerData = formData) => {
  return instance.put(`${baseUrl}/${url}`, data).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};
export const apiPut = (url, data, header = false, headerData = json) => {
  return instance.put(`${baseUrl}/${url}`, data).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};

export const apiDelete = (url, data, header = false, headerData = json) => {
  return instance.delete(`${baseUrl}/${url}?${data}`).then((data) => {
    return Promise.resolve({ statusCode: data.status, ...data });
  });
};